<template>
  <div v-if="preview">
    {{ previewTitle }}
  </div>
  <div
    v-else
    :class="[
      'relative h-full w-full group/cell',
      {
        'cursor-not-allowed': disabled,
      },
    ]"
  >
    <UPopover
      v-model:open="isOpen"
      :ui="{
        width: 'min-w-52 max-w-[280px]',
        wrapper: 'h-full',
        trigger: 'h-full flex items-center',
        base: 'pb-2',
      }"
      :popper="{
        placement: 'bottom-start',
        strategy: 'fixed',
      }"
      :disabled="disabled"
      v-bind="$attrs"
    >
      <template #panel>
        <div class="flex items-center justify-between px-4 pt-3 pb-2">
          <p class="font-semibold text-sm text-gray-900">{{ label }}</p>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click.prevent.stop="isOpen = false"
          />
        </div>
        <div class="px-4">
          <UInput
            v-model="search"
            icon="i-heroicons-magnifying-glass"
            size="sm"
            autofocus
            placeholder="Search option"
            :ui="{
              base: '!rounded-md',
            }"
          />
        </div>
        <div
          class="max-h-[20.175rem] overflow-y-auto scroll-stable minimal-scrollbar mt-4 pl-4 pr-2.5 pb-2 space-y-1"
        >
          <div
            v-for="(option, index) in filteredMenuOptions"
            :key="option.value"
            :class="[
              'flex items-center text-gray-900 cursor-pointer rounded-md gap-2 w-full p-1',
              { 'bg-gray-100': currentFocusOption === index },
            ]"
            data-test="option.value"
            @mouseenter="currentFocusOption = index"
            @mouseleave="currentFocusOption = -1"
            @click="onSelect(option.value)"
          >
            <Checkbox :checked="selectedValues.includes(option.value)" />
            <Tooltip :text="option.label" arrow-class="!top-[unset]">
              <template #default="{ getTextRef }">
                <div
                  class="px-2 py-1 rounded-md"
                  :style="getFieldOptionPreset(option.color)"
                >
                  <span
                    :ref="getTextRef"
                    class="text-sm line-clamp-1 break-all font-medium"
                    data-test="option-item"
                  >
                    {{ option.label }}
                  </span>
                </div>
              </template>
            </Tooltip>
          </div>
        </div>
      </template>
      <div
        :class="[
          'h-full w-full flex items-center py-1',
          {
            '!cursor-not-allowed': disabled,
          },
        ]"
      >
        <div
          :class="[
            'w-full flex',
            showFullLabel
              ? 'overflow-y-auto scroll-stable max-h-20 minimal-scrollbar'
              : 'items-center',
          ]"
        >
          <ResizeLabels
            :labels="labels || []"
            :get-label-style="(color: FieldOptionColor) => getFieldOptionPreset(color) "
            :show-full="showFullLabel"
          />
          <div class="min-w-5" />
        </div>
      </div>
    </UPopover>
    <UButton
      v-if="selectedValues?.length && !disabled"
      size="2xs"
      color="gray"
      variant="ghost"
      icon="i-heroicons-x-mark"
      :class="[
        'absolute top-1/2 -translate-y-1/2 group-hover/cell:flex hidden',
        showFullLabel ? 'px-1.5 right-1.5' : 'right-1',
      ]"
      @click="onClear"
    />
    <Icon
      v-else
      name="heroicons:chevron-down"
      class="text-gray-500 absolute right-1 top-1/2 -translate-y-1/2 group-hover/cell:flex hidden mr-1"
      size="2xs"
    />
  </div>
</template>

<script lang="ts" setup>
import { map } from 'lodash-es'
import type { FieldOptionColor } from '#field/constant'
import { Checkbox } from '#components'
import type { FieldOption, FieldLabel } from '#field/types'

const props = defineProps<{
  value: string | undefined
  label: string
  description?: string
  options: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  showFullLabel?: boolean
}>()

const currentFocusOption = ref(-1)
const isOpen = ref(false)
const search = ref('')
const emit = defineEmits(['change', 'set-callback'])

const selectedValues = ref<string[]>(safeParseStringToJSON(props.value ?? '', []))

const menuOptions = computed<Array<FieldOption>>(() => {
  if (typeof props.options === 'string') {
    return safeParseStringToJSON(props.options, [])
  }

  return props.options
})
const selectedOptions = computed(() => {
  return menuOptions.value?.filter((option) =>
    selectedValues.value?.includes(option.value)
  )
})

const filteredMenuOptions = computed(() => {
  return menuOptions.value?.filter((option) => {
    return option.label.toLowerCase().includes(search.value.toLowerCase())
  })
})

const labels = computed<FieldLabel[]>(() => {
  const labels = selectedOptions.value?.map((option) => {
    return {
      id: option.value,
      name: option.label,
      color: option.color as string,
    }
  })

  return labels
})

const previewTitle = computed(() => {
  const labels = map(selectedOptions.value, 'label')
  return labels.join(', ')
})

const onChange = (value: string[]) => {
  emit('change', JSON.stringify(value), props.fieldId)
}

const onSelect = (value: string) => {
  if (selectedValues.value?.includes(value)) {
    selectedValues.value = selectedValues.value.filter((v) => v !== value)
  } else {
    selectedValues.value.push(value)
  }

  onChange(selectedValues.value)
}

const onClear = () => {
  selectedValues.value = []
  onChange([])
}

watch(
  () => props.value,
  (val) => {
    selectedValues.value = safeParseStringToJSON(val ?? '', [])
  }
)

defineExpose({
  focus: () => {
    isOpen.value = true
  },
})

onMounted(() => {
  emit('set-callback', {
    open: () => {
      isOpen.value = true
    },
  })
})

defineShortcuts({
  arrowup: {
    whenever: [isOpen],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value > 0) {
        currentFocusOption.value--
        return
      }

      currentFocusOption.value = filteredMenuOptions.value?.length - 1
    },
  },
  arrowdown: {
    whenever: [isOpen],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value < filteredMenuOptions.value?.length - 1) {
        currentFocusOption.value++
        return
      }

      currentFocusOption.value = 0
    },
  },
  enter: {
    whenever: [isOpen],
    usingInput: true,
    handler: () => {
      if (
        currentFocusOption.value >= 0 &&
        filteredMenuOptions.value![currentFocusOption.value]
      ) {
        onSelect(filteredMenuOptions.value[currentFocusOption.value].value)
      }
    },
  },
  escape: {
    whenever: [isOpen],
    usingInput: true,
    handler: () => {
      isOpen.value = false
    },
  },
})
</script>
